import { useEffect } from "react"

const AppRedirect = () => {
  useEffect(() => {
    window && window.location.replace("/app/" + window.location.search)
  }, [])
  return null
}

export default AppRedirect
